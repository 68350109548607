import React, { useEffect, useState } from 'react'
import BaseAuth from './BaseAuth'
import { useLocation } from 'react-router'
import { func } from '../../Utilities/logFunc'
import { errors } from '../../Utilities/error'
import { useNavigate } from 'react-router'
import { routes } from '../../Utilities/routesFb'
import { userRequestOTPSignUp } from '../../Utilities/controller'
import { useTheme } from '../../ThemeContext'
import { logout } from '../../Utilities/logout'
import LoginLandingPage from '../LandingPage/LoginLandingPage'
import { useTranslation } from "react-i18next";
import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents"; 
const SignupOtp = () => {
  const globalValues = useTheme()
  const location = useLocation()
  const data = location?.state
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [otp, setOtp] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [timerCount, setTimer] = useState(30)
  
   // This function should set the OTP Timer of 30 seconds.
   const setCountDownTimer = () => {
    setTimer(timerCount - 1);
}

// This function should show/hide Resend OTP Button Visibility.
const showHideResendOTP = () => {
    setTimer(30)
}

useEffect(() => {
  if (timerCount !== 0) {
      setTimeout(() => {
          setCountDownTimer();
      }, 1000);
  }
}, [timerCount]);

  const submitHandler = (e) => {
    e.preventDefault()
    if (!otp || otp?.length == 0) {
      setError(t('error_enter_code'));
    }
    else if (otp.length != 6) {
      setError(t('error_enter_valid_code'));
    }
    else {
      func(otp)
      callToOTPVerficationAPI(otp)
    }
  }

  const callToOTPVerficationAPI = async (otp) => {
    if (otp.length >= 6) {
      let payload = {
        type: "validateOTP",
        username: data?.mobileNumber,
        otp: otp,
        platform:"webapp"
      }
      setLoading(true)
      try {
        const res = await userRequestOTPSignUp(payload)
        func("otp response:",res)
        if (res?.data?.success) {
          eventTracking(events.otp_verified_signup,{
            GJ_MobileNumber: data?.mobileNumber,
            GJ_Country_Code: data?.countryOption,
            GJ_OTP:otp
          });
          eventTracking(events.register,{
            GJ_MobileNumber: data?.mobileNumber,
            GJ_Country_Code: data?.countryOption,
            GJ_Signupcode:otp
          });
          console.log("res?.data::SIGNUP", res?.data)
          globalValues.fetchUserData(res?.data)
          localStorage.setItem('user-id', res?.data?.item?.id);
          localStorage.setItem('token', res?.data?.token)
          localStorage.setItem("country", res?.data?.item?.address?.country);
          localStorage.setItem('isDetailsFilled',res?.data?.item?.isDetailsFilled)

          localStorage.setItem('email', res?.data?.item?.email||'');
          localStorage.setItem('phone', res?.data?.item?.phone||'');

          window.location.href = '/avatar';

          // navigate("/" + routes.avatar)
          // globalValues.token = res.token
          // globalValues.userData = res.item
        }
        else {
          setError(res?.response?.data?.errors?.[0]?.msg)
        }
      } catch (error) {
        func("error", error)
        // if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
        // logout()
        setError(error?.response?.data?.errors?.[0]?.msg)

      }
      // showHideResendOTP()
      setLoading(false)
    }
  }

    // This function should call the Resend OTP API
    const callToResendOTPAPI = async (otp) => {
        let payload = {
            type: "otpRequest",
            username: data?.mobileNumber,
            // otp: otp,
            phoneCode: data?.countryOption
        }
        setLoading(true)
      try {
        func("payload", payload)
        const res = await userRequestOTPSignUp(payload)
        eventTracking(events.resend_signup,{GJ_CountryCode :data?.countryOption,GJ_MobileNumber:data?.mobileNumber})

        func("otp response:",res)
        if (res?.data?.success) {
          // globalValues.fetchUserData(res)
          // localStorage.setItem('token', res?.token)
          // navigate("/" + routes.avatar)
          // globalValues.token = res.token
          // globalValues.userData = res.item
          setTimer(30)
        }
        else {
          setError(res?.response?.data?.errors?.[0]?.msg)
        }
      } catch (error) {
        func("erasasasaror", error)
        // if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
        // logout()
        setError(error?.response?.data?.errors?.[0]?.msg)

      }
      setLoading(false)
    };
  
  return (
    <LoginLandingPage>
    <BaseAuth>
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-7 col-9">
          <div class="tab-content auth-content">
            <form class="row justify-content-center ">
              <h1 class="text-nowrap verification_heading d11-codecpro d11-otp-text text-center lh-1 h2">
              {t("login.enter_verification_code")}
              </h1>
              <h2 class="form-label text-wrap d1-otp-subText d11-codecpro" style={{marginBottom:'5px'}}>
              {t("login.sent_verification_code")}
              </h2>
              <h1 class="Verification_number text-nowrap d11-codecpro mt-2">
                {data?.countryOption} - {data?.mobileNumber}
              </h1>
              <h4 class="form-label text-nowrap d1-otp-subText d11-codecpro"   style={{ marginBottom: "7px", marginTop: "15px" }}>
              {t("login.verification_code_received")}
              </h4>
              <div
                class="col-12 justify-content-center align-items-center"
                style={{marginTop:'2px'}}
              >
                <input
                  type="number"
                  class="form-control"
                  id="inputCouponCode"
                  placeholder={t('login.enter_verification_code')}
                  value={otp.slice(0,6)}
                  maxLength={6}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-","."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onChange={(e) => {
                    setError("");
                    setOtp(prev => e.target.value)
                  }}
                />
                {
                  error && <p className="error" style={{ textAlign: "center" }}>{error}</p>
                }
              </div>
              <div class="d-grid" style={{marginTop:'10px'}}>
                <button

                  class="btn btn-primary btn-arrow mb-3"
                  onClick={(e) => submitHandler(e)}
                >
                  {t("login.submit")}
                </button>
                {/* <div class="links">
                  <h5>
                  {timerCount == 0 ? <a
                      class="link-offset-2 link-underline link-underline-opacity-0 verification-link"
                      href="#" onClick={(e) => callToResendOTPAPI(e) }
                    >
                      Didn’t receive Verification Code?</a>: null}
                  </h5>
                  
                </div>
                <div class="links">
                <h5 className="d11-text-center">
                  {timerCount != 0 ? <a
                      class="link-offset-2 link-underline link-underline-opacity-0 verification-link"
                      href="#"
                    >
                      Request for a new one in {timerCount} seconds</a> : null}
                  </h5>
                  </div> */}
                <div class="links">
                  <h5 className="d11-text-center"><span
                        class="link-offset-2 link-underline link-underline-opacity-0 verification-link d11-otp-resend">  {t("login.didnt_receive_code")} </span></h5>
                  </div>
                
                <div class="links">
                  <h5 className="d11-text-center">
                
                    {timerCount == 0 ? (
                      <span
                        class="link-offset-2 link-underline link-underline-opacity-0 verification-link"
                       style={{color:'var(--primary-color-red)', cursor:'pointer',textDecoration: 'underline !important'}}
                        onClick={(e) => callToResendOTPAPI(e.target.value)}
                      >
                         
                         <b>{t("login.resend")}</b>
                      </span>
                    ) : null}
                  </h5>
                  <h5 className="d11-text-center">
                    {timerCount != 0 ? (
                      <span
                        class="link-offset-2 link-underline link-underline-opacity-0 verification-link d11-otp-resend"
                        
                      
                      >
                        {t("login.request_one", { n: timerCount })}
                      </span>
                    ) : null}
                  </h5>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </BaseAuth>
    </LoginLandingPage>
  )
}

export default SignupOtp
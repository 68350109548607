import React, { useEffect, useReducer, useState } from "react";
import BaseAuth from "./BaseAuth";
import {
  getCountryList,
  requestApplySignupCode,
  userRequestOTP,
  userRequestOTPSignUp,
  requestGoogleLogin
} from "../../Utilities/controller";
import { func } from "../../Utilities/logFunc";
import Loader from "../Common/Loader";
import { url } from "../../Utilities/url";
import { errors } from "../../Utilities/error";
import { useLocation, useNavigate } from "react-router";
import { routes } from "../../Utilities/routesFb";
import { INITIAL_STATE, loginReducer } from "./loginReducer";
import LandingPage from "../LandingPage/LandingPage";
import { INITIAL_STATE_REG, registerReducer } from "./SignupReducer";
import Select from "react-select";
import DropDown from "../Common/DropDown";
import CountryDropDown from "../Common/CountryListDropDown";
import { logout } from "../../Utilities/logout";
import { toast } from "react-toastify";
import LoginLandingPage from "../LandingPage/LoginLandingPage";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pipe from '../../assets/new-images/auth-pipe.png'
import Gogoole from '../../assets/new-images/gogoole.png'
// import { GoogleLogin } from 'react-google-login';
import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents";
import { singularSdk } from "singular-sdk";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
const Login = () => {
  func("login", "login page");
  const [countryList, setCountryList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loginInput, dispatchInput] = useReducer(loginReducer, INITIAL_STATE);
  const [registerInput, dispatchRegisterInput] = useReducer(
    registerReducer,
    INITIAL_STATE_REG
  );
  const [error, setError] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [appliedCouponCode, setAppliedCouponCode] = useState(undefined);
  const [couponError, setCouponError] = useState("");
  const [couponSuccess, setCouponSuccess] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [onAgree, setOnAgree] = useState(false);
  const [tncError, setTncError] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  const location = useLocation()
  const { state } = location
  // console.log(state)
  const [active, setActive] = useState(state || 0)
  const { t } = useTranslation();

  const [loginData, setLoginData] = useState(
    // localStorage.getItem('loginData')
    //   ? JSON.parse(localStorage.getItem('loginData'))
    //   : 
    null
  );
  const translatedText = t("login.i_agree_to_gamerji", {
    interpolation: { escapeValue: false },
  });
  const fetchCountry = async () => {
    setLoader(true);
    try {
      const res = await getCountryList();
      if (res) setCountryList((prev) => res?.list);
      // setCountryOption(prev=> res?.list[2].dialingCode)
      let index = null;
      if(localStorage.getItem('countryCode')){
        res?.list?.filter((item, ind) => {
          if (item?.dialingCode == localStorage.getItem('countryCode')) {
            index = ind;
            return index;
          }
        });
      }
      else{

        res?.list?.filter((item, ind) => {
          if (item?.dialingCode == "+91") {
            index = ind;
            return index;
          }
        });
      }
      func("index", index);
      setIndex(index);
      dispatchInput({
        type: "CHANGE_INPUT",
        payload: {
          name: "countryOption",
          value: res?.list?.[index]?.dialingCode,
        },
      });
      dispatchInput({
        type: "CHANGE_INPUT",
        payload: { name: "code", value: res?.list?.[index]?._id },
      });
      dispatchRegisterInput({
        type: "CHANGE_INPUT",
        payload: {
          name: "countryOption",
          value: res?.list?.[index]?.dialingCode,
        },
      });
      dispatchRegisterInput({
        type: "CHANGE_INPUT",
        payload: { name: "code", value: res?.list?.[index]?._id },
      });
    } catch (error) {
      func("error", error);
    }
    setLoader(false);
  };
  useEffect(() => {
    fetchCountry();
  }, []);
  const onChangeHandler = (e) => {
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };
  const onSignupChangeHandler = (e) => {
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };
  const loginHandler = (e) => {
    e.preventDefault();
    // window['gtag_report_conversion']('')

    if (!loginInput?.mobileNumber || loginInput?.mobileNumber?.length == 0) {
      setError(t('error_enter_phone'));
    }
    else if (loginInput?.countryOption == "+91") {
      if (loginInput?.mobileNumber.length != 10) {
        setError(t('error_enter_valid_phone'));
      } else {
        setError("");
        callToOTPRequestAPI();
      }
    } else {
      if (loginInput?.mobileNumber.length < 8 || loginInput?.mobileNumber.length > 14) {
        setError(t('error_enter_valid_phone'));
      } else {
        setError("");
        callToOTPRequestAPI();
      }
    }

    // else if (loginInput?.mobileNumber?.length != 10) {
    //   setError(errors?.error_enter_valid_phone);
    // } else {
    //   callToOTPRequestAPI();
    // }
    // const onSignupChangeHandler = (e) => {
    //   dispatchRegisterInput({ type: 'CHANGE_INPUT', payload: { name: e.target.name, value: e.target.value } })
    // }
    // const loginHandler = (e) => {
    //   e.preventDefault()
    //   if (!loginInput?.mobileNumber || loginInput?.mobileNumber?.length == 0) {
    //     setError(errors.error_enter_phone)
    //   }
    //   else if (loginInput?.mobileNumber?.length != 10) {
    //     setError(errors?.error_enter_valid_phone)
    //   }
    //   else {
    //     callToOTPRequestAPI();

    //   }
    // }
  }

  useEffect(() => {
    let getLoginhandler = localStorage.getItem("loginHandler")
    if (getLoginhandler == "register") {
      localStorage.removeItem("loginHandler");
      setActive(1)
    }
    else {
      localStorage.removeItem("loginHandler");
      setActive(0)
    }
  }, [])

  const emailLoginHandler = (e) => {
    localStorage.setItem("loginHandler", "email-login")
    navigate("/email-login")
  }
  const emailSignupHandler = (e) => {
    localStorage.setItem("loginHandler", "email-signup")
    navigate("/email-signup")
  }

  const customFilter = (option, searchText) => {
    console.log(option, searchText)
    if (
      option.data.dialingCode.includes(searchText)

    ) {
      return true;
    } else {
      return false;
    }
  }

  // request OTP after login
  const callToOTPRequestAPI = async () => {
    var payload = {
      type: "otpRequest",
      username: loginInput?.mobileNumber,
      phoneCode: loginInput?.countryOption,
      country: loginInput?.code,
    };
    localStorage.setItem('country', loginInput?.code)
    localStorage.setItem("loginInfo", JSON.stringify(payload));

    setLoading(true)
    try {
      const res = await userRequestOTP(payload)
      func('login res', res?.response?.data?.errors?.[0]?.msg)
      if (res?.data?.success) {
        eventTracking(events.login_with_phone, {
          GJ_MobileNumber: loginInput?.mobileNumber,
          GJ_CountryCode: loginInput?.countryOption,
          // MG_UserID:res?.item?._id
        });

        navigate("/" + routes.otp, { state: loginInput })

      } else {
        setError(res?.response?.data?.errors?.[0]?.msg)
      }
    } catch (error) {
      func("userRequestOTP error", error)
      setError(error?.response?.data?.errors?.[0]?.msg)
    }
    setLoading(false)
  }

  const handlerSelect = (e) => {
    setCountryCode(e);
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: "code", value: e?._id },
    });
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: "countryOption", value: e?.dialingCode },
    });
  };
  const handlerSelectRegister = (e) => {
    setCountryCode(e);
    func("onChange",)
    setCouponError("")
    setCouponSuccess("")
    setCouponCode("")
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: "code", value: e?._id },
    });
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: "countryOption", value: e?.dialingCode },
    });
  };
  // request OTP after login


  // Registration
  const checkValidation = (e) => {
    // console.log("clicked")
    e.preventDefault();
    if (
      !registerInput?.mobileNumber ||
      registerInput?.mobileNumber?.length == 0
    ) {
      setSignupError(t('error_enter_phone'));
      return;
    } else {
      if (registerInput?.countryOption == "+91") {
        if (registerInput?.mobileNumber.length != 10) {
          setSignupError(t('error_enter_valid_phone'));
          return;
        } else {
          setError("");
        }
      } else {
        if (registerInput?.mobileNumber.length < 8 || registerInput?.mobileNumber.length > 14) {
          setSignupError(t('error_enter_valid_phone'));
          return;
        }
      }
    }

    if (onAgree) {
      setTncError("");
      callToOTPRequestAPISignup();
    } else {
      setTncError(t('error_agree_terms_and_conditions'));
    }
  };

  // Update the state based on whether the checkbox is checked or not
  const handleCheckboxChange = (e) => {
    setTncError("");
    setOnAgree(e.target.checked);
  };

  // call signup API
  const callToOTPRequestAPISignup = async () => {
    if (!registerInput?.countryOption) {
      setSignupError(t('error_select_country_code'));
      return;
    }
    var payload = {
      type: "otpRequest",
      username: registerInput?.mobileNumber,
      phoneCode: registerInput?.countryOption,
      country: registerInput?.code,
      code: couponCode,
      platform: "webapp"
    };
    localStorage.setItem('country', registerInput?.code)
    if (appliedCouponCode) {
      payload.code = couponCode;
    }
    eventTracking(events.sign_up_using_mobile, {
      GJ_CountryCode: registerInput?.countryOption,
      GJ_MobileNumber: registerInput?.mobileNumber,
    });



    setLoading(true);
    func("signup payload", payload);
    try {
      const res = await userRequestOTPSignUp(payload);
      func("register res", res);
      if (res?.data?.success) {
        singularSdk.event("Register_btn",
          {
            GJ_CountryCode: registerInput?.countryOption,
            GJ_MobileNumber: registerInput?.mobileNumber,
          });

        navigate("/" + routes.signupotp, { state: registerInput });
      } else {
        setSignupError(res?.response?.data?.errors[0]?.msg);
      }
    } catch (error) {
      func("signup error", error);
      if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
        logout()
      toast.error(error?.response?.data?.errors?.[0]?.msg)
      setSignupError(error?.response?.data?.errors?.[0]?.msg);
    }
    setLoading(false);
  };

  // call coupon apply API
  const applySingupCode = async (e) => {
    e.preventDefault();
    if (couponCode == "") {
      setCouponError(t('error_enter_coupon_code'));
    } else {
      setLoading(true);
      try {
        const res = await requestApplySignupCode({
          code: couponCode,
          type: "signupCode",
          country: registerInput.code
        });
        eventTracking(events.apply_signup, {
          GJ_CouponCode: couponCode,
        });
        // setAppliedCouponCode(res.item)
        console.log(res);
        if (res?.status === 200) {
          if (res?.data?.success) {
            setCouponError('')
            setCouponSuccess("Signup Code Applied Successfully!");
          }
        } else if (res?.response?.data?.errors) {
          setCouponError(res?.response?.data?.errors?.[0]?.msg);
        }


      } catch (error) {

        func("error", error);
        if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
          logout()
        setSignupError(error?.response?.data?.errors?.[0]?.msg);
      }
      setLoading(false);
    }
  };

  const customStyles = {

    menu: (defaultStyles, state) => ({
      ...defaultStyles,
      // backgroundColor: "red",
      minHeight: '15rem',
      backgroundColor: "#fff",
      color:"#000"
    }),

    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#000" : "#000",
      backgroundColor: state.isSelected
        ? "#fff !important"
        : "#fff",
      height: '2.5rem',
      "&:hover": {
        color: 'var(--accent-color)'
      },
      padding: "0.65rem 1.25rem",
      borderRadius: '20px',


    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "#fff",
      padding: "0.5rem",
      border: "none",
      boxShadow: "none",
      borderRadius: "50px",
      display: "flex",
      alignItems: "center",
      caretColor: 'transparent',
      minHeight: '3.75rem',
      border: '1px solid var(--body-color)'

    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#000",
      width: "4.7rem",
      marginRight: "0.75rem",
      padding: "0.35rem",

    }),
    indicatorsSeparator: (defaultStyles) => ({
      ...defaultStyles,
      color: "#000",
      display: "none",
    }),
    indicatorContainer: (defaultStyles) => ({
      ...defaultStyles,
      padding: "0",
      backgroundColor: "red",
    }),

    input: (defaultStyles) => ({
      ...defaultStyles,
      color: "#000",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "1.85rem",

    }),
    dropdownIndicator: (defaultStyles) => ({

      color: '#000',
      width: '1.5rem',
      height: '1.5rem',
      padding: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    })
  };


  const responseGoogleOnSuccess = async (googleData) => {
    // console.log("googleData mobile in 123", googleData, googleData.credential, typeof googleData.credential);

    const decoded = jwtDecode(googleData.credential);
    // console.log("***********", decoded)
    setLoginData(googleData);
    // localStorage.setItem('loginData', JSON.stringify(googleData));
    let payload = {
      "googleData": decoded,
      // "campaignId": campaignId,
      "platform": "webapp",
    }
    let response = await requestGoogleLogin(payload);
    // console.log("requestGoogleLogin:: response",response)
    if (response) {
      console.log("requestGoogleLogin:: response", response?.response?.data?.errors)
      if (response?.data?.success === true) {
        localStorage.setItem('token', response?.data?.token);
        localStorage.setItem("country", response?.data?.item?.address?.country);
        localStorage.setItem('isDetailsFilled', response?.data?.item?.isDetailsFilled)
        // if (response?.data?.item?.isDetailsFilled) window.location.href = "/" + routes.home;
        if (response?.data?.item?.isDetailsFilled) {
          // console.log(localStorage.getItem('code'))
          if (localStorage.getItem('code')) {
            window.location.href = "/" + routes.home + `?code=${localStorage.getItem('code')}`;

          }

          else
            window.location.href = "/" + routes.home;
        }
        else window.location.href = "/avatar";

        // if (response.item.isSingup === true) {
        //   localStorage.setItem('profile', JSON.stringify(response));
        //   window.location.href = '/avatar';
        // } else {
        //   window.location.href = '/';
        // }
      } else {
        setError(response?.response?.data?.errors ? response?.response?.data?.errors?.[0]?.msg : t('error_Oops_something_went_wrong_please_try_again'));
      }
      // console.log("responseGoogleOnSuccess====response==>", response);
      //  setLoginData(response);
      // localStorage.setItem('loginData', JSON.stringify(response));
    }
    // const res = await fetch('/api/google-login', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     token: googleData.tokenId,
    //   }),
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // });
    // const data = await res.json();
    console.log("responseGoogleOnSuccess==>", googleData);
  };

  const responseGoogleOnFailure = (response) => {
    console.log("responseGoogleOnFailure==>", response);
    setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('error_Oops_something_went_wrong_please_try_again'));
  };
  return (
    <>
      {(
        <LoginLandingPage>
          <BaseAuth>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-sm-9">
                <ul
                  className="nav nav-pills  d-flex justify-content-between auth-nav"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation" style={{ width: '50%' }}>
                    <button
                      className={`nav-link ${active === 0 ? 'active' : ""}`}
                      id="login-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#login-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="login-tab-pane"
                      aria-selected="true"
                      style={{ width: '100%' }}
                      onClick={() => {

                        eventTracking(events.LOGIN_TAB);
                      }}
                    >
                      {t("login.login")}
                    </button>
                  </li>
                  {/* <li className="nav-item pipe-nav-div" role="#" style={{width:'4%'}}>
                    <img src={Pipe} />
                  </li> */}
                  <li className="nav-item" role="presentation" style={{ width: '50%' }}>
                    <button
                      className={`nav-link ${active === 1 ? 'active' : ""}`}
                      id="register-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#register-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="register-tab-pane"
                      aria-selected="false"
                      style={{ width: '100%' }}
                      onClick={() => eventTracking(events.REGISTER_TAB)}
                    >
                      {t("login.register")}
                    </button>
                  </li>
                </ul>
                <div className="tab-content auth-content">
                  {/* LOGIN TAB*/}
                  <div
                    className={`tab-pane fade ${active === 0 ? 'show active' : ""}`}
                    id="login-tab-pane"
                    role="tabpanel"
                    aria-labelledby="login-tab"
                    tabindex="0"
                  >
                    <form className="row">
                      <div className="col-4">
                        <label
                          for="inputCoutryCode"
                          className="form-label text-nowrap"
                        >
                          {t("login.country_code")}
                        </label>
                        {
                          countryList?.length > 0 &&
                          <Select
                            styles={customStyles}
                            name="countryOption"
                            // value={countryCode}
                            defaultValue={countryList?.[index]}
                            options={countryList}
                            onChange={(e) => handlerSelect(e)}
                            getOptionLabel={(ele) => (
                              <div
                                className="d11-menu text-black"
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <span class="icon text-black">
                                  <img
                                    class="me-1"
                                    // style={{
                                    //   borderRadius: "50%",
                                    //   width: "24px",
                                    //   height: "24px",
                                    //   marginRight: "5px",
                                    // }}
                                    src={url.imageUrl + ele?.flag?.default}
                                    alt=""
                                  />
                                </span>{" "}
                                {ele?.dialingCode}
                              </div>
                            )}
                            isSearchable={true}
                            filterOption={customFilter}

                          />
                        }
                        {/* <CountryDropDown options={countryList} /> */}
                      </div>

                      <div className="col-8">
                        <label for="inputMobileNumber" className="form-label">
                          {t("login.mobile_number")}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="inputMobileNumber"
                          placeholder={t("login.enter_mobile_number")}
                          name="mobileNumber"
                          onKeyDown={(e) => {
                            ["e", "E", "+", "-", "."].includes(e.key) &&
                              e.preventDefault()
                          }
                          }
                          onChange={(e) => {
                            onChangeHandler(e)
                            if (e.target.value?.length != null && e.target.value?.length != "null" && e.target.value?.length != undefined && e.target.value?.length != "undefined" && e.target.value?.length != "") {
                              setError("")
                            }
                          }}
                          maxLength={14}
                          // pattern='d\+\.\d\d$'
                          value={loginInput?.mobileNumber.slice(0, 14)}
                        />
                      </div>

                      <div className="col-12 d-grid">
                        <button
                          className="btn btn-primary btn-arrow"
                          onClick={(e) => {
                            window.gtag_report_conversion("");
                            loginHandler(e);
                          }
                          }
                        >
                          {t("login.login")}
                        </button>
                        {error && (
                          <p className="error" style={{ textAlign: "center" }}>
                            {error}
                          </p>
                        )}
                      </div>

                      <div className="col-12 d-flex justify-content-center">
                        <label className="form-label">
                          {t("login.or")}
                        </label>
                      </div>

                      {/* <div className="col-12 d-grid other_login">
                        <button
                          className="btn btn-outline-primary text-capitalize"
                          type="button"
                          onClick={emailLoginHandler}
                        >
                          {t("login.login_with_email")}
                        </button>
                      </div> */}
                      {/* <div className="col-12 d-grid other_login">
                        <button
                          className="btn btn-outline-primary text-capitalize btn-google"
                          type="button"
                        >
                          {t("login.sign_in_with_google")}
                        </button>
                        
                      </div> */}
                      {/* GOOGLE LOGIN  */}
                      <div className="col-12 d-flex justify-content-center other_login">
                          {
                            <p className="text-center mt-2">
                              <GoogleLogin
                              
                                // clientId={
                                //   "454874517092-tk61r0pb6f79ecte31ugdcde36old1c5.apps.googleusercontent.com"
                                // }
                                className="google-login"
                                onSuccess={responseGoogleOnSuccess}
                                onFailure={responseGoogleOnFailure}
                                cookiePolicy={"single_host_origin"}
                                theme={"light"}
                                width={"350"}
                                height={"60"}
                                shape={"circle"}

                              // uxMode={'redirect'}
                              // redirectUri={"http://localhost:3000/register/"}
                              >
                                <span className="buttonText" style={{"display":"inline-block","verticalAlign":"middle","paddingLeft":"42px","paddingRight":"42px","fontSize":"14px","fontWeight":"700","textAlign":"center","fontFamily":"\"Roboto\",sans-serif","marginLeft":"3rem"}}> Sign in with Google</span>
                              </GoogleLogin>
                            </p>
                          }
                      </div> 
                      {/* GOOGLE LOGIN  */}
                    </form>
                  </div>
                  {/* Registertion Tab */}
                  <div
                    className={`tab-pane fade ${active === 1 ? 'show active' : ""}`}
                    id="register-tab-pane"
                    role="tabpanel"
                    aria-labelledby="register-tab"
                    tabindex="0"
                  >
                    <form className="row">
                      <div className="col-4">
                        <label
                          for="inputCoutryCode"
                          className="form-label text-nowrap"
                        >
                          {t("login.country_code")}
                        </label>
                        {
                          countryList?.length > 0 &&
                          <Select
                            styles={customStyles}
                            name='countryOption'
                            // value={countryCode}
                            defaultValue={countryList?.[index]}
                            options={countryList}
                            onChange={(e) => handlerSelectRegister(e)}
                            getOptionLabel={ele => (
                              <div  className="d11-menu text-black" style={{ display: 'flex', alignItems: 'center' }}>
                                <span class="icon" >
                                  <img  class="icon-16 me-1 d11-country-flag text-black"
                                  
                                  src={url.imageUrl + ele?.flag?.default} alt="" />
                                </span>{"  "}<span >{ele?.dialingCode}</span>
                              </div>
                            )}
                            isSearchable={true}
                            filterOption={customFilter}
                          />
                        }
                      </div>
                      <div className="col-8">
                        <label for="inputMobileNumber" className="form-label"
                        >
                          {t("login.mobile_number") || "Mobile Number"} </label>

                        <input
                          type="number"
                          className="form-control"
                          id="inputMobileNumber"
                          placeholder={t("login.enter_mobile_number") || "Enter Mobile Number"}
                          name='mobileNumber'
                          onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                          onChange={(e) => {
                            setSignupError("")
                            onSignupChangeHandler(e)

                          }}
                          maxLength={14}
                          value={registerInput?.mobileNumber.slice(0, 14)}
                        />
                      </div>
                      <div className="col-md-12">
                        <label for="inputCouponCode" className="form-label"
                        >
                          {t("login.coupon_code") || "Coupon Code"}
                        </label>
                        <div className="couponCode-block">
                          <input
                            type="text"
                            className="form-control"
                            id="inputCouponCode"
                            placeholder={t("login.enter_coupon_code") || "Enter Coupon Code"}
                            name='couponCode'
                            onChange={(e) => {
                              setCouponCode(prev => e.target.value)
                              setCouponError("")
                            }
                            }
                            value={couponCode}
                          />
                          {
                            couponSuccess ? <button
                              className="btn btn-primary"
                              // style={{ position: 'absolute', right: '0', top: '0', width: '120px', height: '100%' }}
                              onClick={(e) => {
                                eventTracking(events.REMOVE_SIGNUP_CODE, {
                                  GJ_CouponCode: couponCode,
                                });
                                e.preventDefault()
                                setCouponError('')
                                setCouponCode('')
                                setCouponSuccess('')
                              }
                              }
                            >
                              {t("login.remove") || "Remove"}
                            </button> :
                              <button
                                className="btn btn-primary"
                                // style={{ position: 'absolute', right: '0', top: '0', width: '120px', height: '100%' }}
                                onClick={(e) => applySingupCode(e)}
                              >
                                {t("login.apply") || "Apply"}
                              </button>
                          }

                        </div>
                        {couponError && <p className='error'>{couponError}</p>}
                        {couponSuccess && <p style={{ color: "green", marginTop: '20px', marginLeft: '10px' }}>{couponSuccess}</p>}
                      </div>
                      <div className="col-12">
                        <div className="form-check d-flex justify-content-start">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="gridCheck"
                            name="checkBox"
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-terms " for="gridCheck">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: translatedText,
                              }}
                            />
                            {/* I agree to D11’s <Link to={'/'+ routes.termsAndCondition} target="_blank"><strong>Terms & Conditions</strong> {" "} </Link> and {" "}
                            <Link to={'/' + routes.policy}  target="_blank"><strong> Privacy Policy </strong></Link> */}
                          </label>
                        </div>
                        {tncError && <p className='error'>{tncError}</p>}

                      </div>
                      <div className="col-12 d-grid">
                        <button type="submit" className="btn btn-primary btn-arrow" onClick={(e) => checkValidation(e)}>
                          {t("login.register") || "Register"}
                        </button>
                      </div>
                      {func("sigin", error)}
                      {
                        signupError && <p className='error' style={{ textAlign: 'center' }}>{signupError}</p>
                      }

                      <div className="col-12 d-flex justify-content-center">
                        <label className="form-label">
                          {t("login.or")}
                        </label>
                      </div>

                      {/* <div className="col-12 d-grid other_login">
                        <button
                          className="btn btn-outline-primary text-capitalize"
                          type="button"
                          onClick={emailSignupHandler}
                        >
                          {t("premium.register_with_email")}
                        </button>
                      </div> */}
                      {/* <div className="col-12 d-grid other_login">
                        <button
                          className="btn btn-outline-primary text-capitalize btn-google"
                          type="button"
                        >
                          {t("login.register_in_with_google")}
                        </button>
                      </div> */}
                      {/* GOOGLE LOGIN  */}
                      <div className="col-12 d-flex justify-content-center other_login">
                          {
                            <p className="text-center mt-2">
                              <GoogleLogin
                                // clientId={
                                //   "454874517092-tk61r0pb6f79ecte31ugdcde36old1c5.apps.googleusercontent.com"
                                // }
                                className="google-login"
                                onSuccess={responseGoogleOnSuccess}
                                onFailure={responseGoogleOnFailure}
                                cookiePolicy={"single_host_origin"}
                                theme={"light"}
                              width={"350"}
                                height={"60"}
                                shape={"circle"}
                              // uxMode={'redirect'}
                              // redirectUri={"http://localhost:3000/register/"}
                              >
                                <span className="buttonText" style={{"display":"inline-block","verticalAlign":"middle","paddingLeft":"42px","paddingRight":"42px","fontSize":"14px","fontWeight":"700","textAlign":"center","fontFamily":"\"Roboto\",sans-serif","marginLeft":"3rem"}}> Sign in with Google</span>
                              </GoogleLogin>
                            </p>
                          }
                      </div> 
                      {/* GOOGLE LOGIN  */}

                    </form>


                  </div>
                </div>
              </div>
            </div>
            {loader ? <Loader /> : <></>}
          </BaseAuth>
        </LoginLandingPage>
      )}
    </>
  );
};
export default Login;
